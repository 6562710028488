.reviews-link {
    color: $text__color;
    cursor: pointer;
    padding: 1.2rem 0;
    border: none;
    outline: none;
    width: 100%;
    .rating {
        font-weight: $font-weight__bold;
    }
    .star-icon {
        transition: transform 0.4s ease;
    }
    &:hover, &:active, &:focus {
        color: $text__color;
        text-decoration: none;
        border: none;
        transform: none;
        .star-icon {
            transform: rotate(230deg) scale(1.4);
        }
    }
}
#reviews-modal {
    display: none;
    .rating {
        font-weight: $font-weight__bold;
    }
}
.review-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
