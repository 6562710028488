//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    position: relative;
    z-index: 7;
}

.header {
    &.content {
        // @extend .abs-add-clearfix;
        // padding-top: $indent__s;
        // padding-bottom: $indent__s;
        position: relative;
    }
}

.logo {
    //float: left;
    margin: 0 10px 0 52px;
    max-width: 60%;
    position: relative;
    z-index: 5;
    font-size: 0;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.header-contact {
    a {
        color: #fff;
        font-weight: bold;
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.customer.dropdown {
    @include lib-dropdown(
        $_toggle-selector: ".customer-toggle",
        $_options-selector: ".customer-dropdown",
        $_dropdown-toggle-icon-content: $icon-account,
        $_dropdown-toggle-active-icon-content: $icon-account,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 35px,
        $_icon-font-line-height: 33px,
        $_icon-font-color: $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-color-active: $header-icons-color
    );
    color: $color-white;
    margin: 0 0 0 $indent__s;
    .customer-toggle {
        > span {
            display: none;
        }
    }
}

$mobile-header-padding: 1.4rem;
$desktop-header-padding: 1.5rem;

.mobile-header-top-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;
    @media (max-width: 768px) {
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

.mobile-header-bottom-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;
    @media (max-width: 768px) {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

//
//  Business USPs
//  ---------------------------------------------

.usp-container {
    background: #fff;
    color: $primary__color__text__lighter;
    padding: 1rem 2rem;
    border-top: 1px solid $panel__divider-color;
    box-shadow: $panel-shadow;
    z-index: 3;
}

#business-usps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    > li {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
    }
    .reviewsio-word {
        display: flex;
        align-items: center;
        justify-content: center;
        @include max-screen($screen__s) {
            flex-direction: column;
            gap: 3px;
        }
    }
}

.subnav-message-container {
    background: $color-ss-blue;
    color: #fff;
    padding: 8px 20px;
    text-align: center;
    font-weight: bold;
    p {
        margin: 0;
    }
}

$curve-adornment-height: 20px;

.curve-container {
    position: relative;
    overflow: hidden;
    height: $curve-adornment-height;
    display: flex;
    justify-content: center;
}

$header-height: 400px;

.content-header {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    .content-header_content {
        position: relative;
        order: 1;
        grid-area: 1 / 1 / 1 / 3;
        z-index: 2;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    // .page-title {
    // }
    .page-subtitle {
        color: $primary__color__text;
        font-size: $font-size__l;
        font-weight: $font-weight__light;
        margin: 0;
    }
    .content-header_content-wrapper {
        width: 45%;
        position: relative;
        .underlay {
            width: 116%;
            height: 164%;
            background: linear-gradient(136deg, #fff, #ffffffC6);
            position: absolute;
            z-index: -1;
            filter: blur(35px);
            margin-top: -12%;
            border-radius: 100px;
        }
    }
    .header-image-info {
        display: inline-flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: -2rem;
        background: #fff;
        padding: 1rem 1.2rem;
        margin-right: 2rem;
        border-radius: 99px;
        border-width: 1px;
        border-style: solid;
        border-color: #fff;
        box-shadow: 0px 2px 10px #290b79a0;
        font-size: 1.4rem;
        color: $text__color;
        transition: all 0.2s ease;
        line-height: normal;
        &:hover {
            border-color: $color-ss-blue;
            color: $color-ss-blue;
            text-decoration: none;
            cursor: pointer;
        }
        &::before {
            content: '';
            display: inline-block;
            width: 21px;
            height: 21px;
            margin-right: 0.8rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cg transform='translate(0.5 0.5)'%3E%3Cg style='isolation:isolate'%3E%3Ccircle cx='10' cy='10' r='10' fill='%2329c4cf' stroke='%2329c4cf' stroke-width='1'/%3E%3C/g%3E%3Ctext transform='translate(8 15)' fill='%23fff' font-size='14' font-family='Helvetica-Bold, Helvetica' font-weight='700'%3E%3Ctspan x='0' y='0'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E");
        }
        .info-label {
            font-weight: 500;
            margin-top: 0.3rem;
            margin-right: 0.5rem;
        }
        @include max-screen($screen__xl) {
            bottom: 2rem;
        }
        @include max-screen($screen__l) {
            bottom: 8rem;
        }
        @include max-screen($screen__m) {
            box-shadow: $panel-shadow;
            top: -30px;
            bottom: unset;
        }
        @include max-screen($screen__s) {
            display: none;
        }
    }
    .content-header_image {
        position: relative;
        order: 2;
        grid-area: 1 / 2 / 1 / 3;
        z-index: 1;
        overflow: hidden;
        height: 100%;
        max-height: 500px;
        .image-container {
            height: 100%;
            @include max-screen($screen__s) {
                height: 0;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .img-overlay {
        @include position-absolute-fill();
        background: linear-gradient(105deg, white 18%, rgba(255, 255, 255, 0) 90%);
        width: 70%;
        @include max-screen($screen__m) {
            display: none;
        }
    }
    .content-header_description {
        // margin-bottom: 3rem;
    }
    .breadcrumbs {
        width: 100%;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding: 0;
        margin: 0 0 1rem 0;
        z-index: 1;
    }
    @include max-screen($screen__l) {
        .content-header_content {
            padding-bottom: 9rem;
        }
    }
    @include max-screen($screen__m) {
        grid-template-columns: 1fr;
        .content-header_content {
            order: 2;
            grid-area: unset;
            padding-bottom: 3rem;
        }
        .content-header_content-wrapper {
            width: unset;
        }
        .content-header_image {
            order: 1;
            grid-area: unset;
            max-height: unset;
            img {
                height: unset;
                width: unset;
            }
        }
    }
    &.category {
        .content-header_content {
            padding-top: 7rem;
            height: 100%;
            @include max-screen($screen__m) {
                padding-top: 2rem;
            }
        }
        .page-title {
            margin-bottom: 1rem;
        }
        .page-subtitle {
            margin-bottom: 6rem;
        }
    }
    &.product {
        .content-header_content {
            height: 100%;
            padding-top: 2rem;
            padding-bottom: 6rem;
            @include max-screen($screen__m) {
                padding-bottom: 2rem;
            }
        }
        .content-header_image {
            max-height: 12rem;
            img {
                filter: blur(10px);
            }
        }
        @include max-screen($screen__m) {
            .content-header_image {
                display: none;
            }
            .breadcrumbs {
                margin: 0;
            }
        }
    }
}
.content-header_adornment {
    position: absolute;
    bottom: -72px;
    @include curved-edge(#fff);
    z-index: 4;
    @include max-screen($screen__m) {
        display: none;
    }
}

.content-header-container {
    position: relative;
    // //height: $header-height;
    // display: flex;
    // justify-content: center;
    overflow: hidden;
    // color: $color-white;
    .breadcrumbs {
        position: absolute;
        width: 100%;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        z-index: 1;
        .items {
            color: $color-white;
        }
    }
    // .content-image-container {
    //     @include position-absolute-fill();
    //     > picture {
    //         display: block;
    //         height: auto;
    //         max-width: 100%;
    //         width: 100%;
    //         > img {
    //             width: 100%;
    //         }
    //     }
    // }
    .content-overlay {
        @include position-absolute-fill();
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        width: 60%;
        @media (max-width: 768px) {
            display: none;
        }
    }
    // .content-header-content-container {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     text-align: center;
    // }
    // .content-header-content {
    //     padding: $indent__base * 4.5 0;
    // }
    .content-text {
        margin-bottom: 3rem;
    }
    .content-short-description {
        margin-bottom: 3rem;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .content-header-container {
        .breadcrumbs {
            margin-left: $indent__s;
            margin-left: $indent__s;
        }
    }

    .content-header-container {
        .content-image-container {
            position: absolute;
            > picture {
                display: block;
                height: auto;
                min-height: $header-height;
                max-width: unset;
                > img {
                    width: 100%;
                }
            }
        }
        .content-header-content {
            padding: $indent__base * 3 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        //padding: 0 8px;
    }

    .header {
        &.panel {
            display: none;

            > .header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        // &.content {
        //     @extend .abs-add-clearfix-desktop;
        //     padding: ($indent__s + 6) 2rem;
        //     > .customer.dropdown {
        //         float: right;
        //     }
        // }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            //border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: 0;
        font-size: 0;

        img {
            max-height: inherit;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .header {
        &.content {
            > .customer.dropdown {
                display: none;
            }
        }
    }

    .usp-container {
        padding: 8px 4px;
    }
}
