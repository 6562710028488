.button,
a.button {
    display: inline-block;
    @include button-size(
        1.5rem,
        2rem,
        $font-size__base,
        $font-weight__bold,
        normal,
        $btn-border-radius
    );
    &.fill {
        @include button-variant($color-ss-blue, $color-ss-blue, #fff, #fff);
    }
    &.fill-dark {
        @include button-variant($color-ss-darkblue, $color-ss-darkblue, #fff, #fff);
    }
    &.outline {
        @include button-outline-variant(
            $primary__color__text,
            $color-ss-blue,
            $color-ss-blue
        );
        &.white {
            @include button-outline-variant($color-white, $color-white);
        }
    }
    &.ghost {
        @include button-ghost-variant(
            $color-ss-blue,
            lighten($color-ss-blue, 45%),
            $color-ss-blue
        );
        &.white {
            @include button-outline-variant($color-white, $color-white);
        }
    }
    &.link {
        @include button-link-variant($color-ss-blue, transparent);
    }
    &.underline {
        @include button-underline-variant($color-ss-blue);
    }
    &.small {
        @include button-size(
            0.5rem,
            0.75rem,
            $font-size__s,
            $font-weight__bold,
            normal,
            100px
        );
    }
    &.medium {
        @include button-size(
            1rem,
            1.5rem,
            $font-size__base,
            $font-weight__bold,
            normal,
            $btn-border-radius
        );
    }
    &.min-width {
        min-width: $btn-min-width;
    }
}

// .button__ghost,
// a.button__ghost {

//   @include button-size($indent__s, $indent__s, $font-size__base, normal, 100px);
// }
