.catalog-product-view {
    .price-wrapper {
        .price-currency {
            //color: $color-ss-pink;
            .price {
            }
        }
    }
    .starting-at {
        // font-size: $font-size__l;
        font-weight: 700;
        color: $color-ss-blue;
    }
}

.category-description {
    margin-top: $header-content-overlap;
    position: relative;
    z-index: 4;
}

.sticky-summary {
    position: sticky;
    position: -webkit-sticky;
    top: $indent__xl;
}

.product-usps {
    margin-bottom: 0;
    ul {
        list-style: none;
        margin-bottom: 0;
        padding: $indent__xs 0 0 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-wrap: wrap;
        li {
            margin: 0;
            padding: $indent__xs $indent__base 0 0;
            width: 50%;
            font-weight: $font-weight__bold;
            font-size: 0.95em;
            &::before {
                content: "\2714";
                color: $primary__color;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
            }
        }
    }
}

.label-note {
    font-size: $font-size__s;
    margin-left: 0.2em;
}

.product-options-bottom {
    .estimated-delivery-placeholder {
        margin-bottom: 1.5rem;
    }
    .estimated-delivery {
        margin-bottom: 1.5rem;
    }
    .estimated-delivery-date {
        font-weight: $font-weight__bold;
        color: $color-ss-blue;
    }
    .price-box {
        .price-wrapper {
            .price-currency {
                font-size: 2.4rem;
                .price {
                    font-size: 2.4rem;
                }
                .tax-info {
                    font-size: $font-size__l;
                    color: #666;
                    margin-left: 0.8rem;
                }
            }
        }
    }
    .button.tocart {
        &.buy {
            border-color: $color-ss-darkpink;
            background-color: $color-ss-darkpink;
            &:hover {
                border-color: $color-ss-pink;
                background-color: $color-ss-pink;
                box-shadow: 0 1px 8px 0 $color-ss-pink;
            }
        }
        &.quote {
            border-color: $color-ss-blue;
            background-color: $color-ss-blue;
        }
    }
}

.order-options-note {
    font-size: 1.4rem;
}

.sellable-badge {
    color: $color-ss-link;
    padding: 0.8rem 1.2rem;
    background: adjust-color($color-ss-link, $alpha: -0.92);
    border-radius: 0.5rem;
    line-height: 1;
    &.buy {
        color: $color-ss-darkpink;
        background: adjust-color($color-ss-pink, $alpha: -0.92);
    }
}

//
//  Product
//  ---------------------------------------------

.intro-section {
    margin-top: $header-content-overlap;
    position: relative;
    z-index: 4;
    @media (max-width: $screen__m) {
        margin-top: 0;
    }
    .overview {
        height: 100%;
    }
}

.info-tab-content {
    min-height: 28rem;
    @include max-screen($screen__m) {
        min-height: unset;
    }
}

#image-gallery {
    .img-description {
        position: absolute;
        bottom: 28px;
        left: 0;
        color: #fff;
        text-shadow: 1px 1px 2px #000;
        padding: 0.6rem 1rem 0.6rem 2rem;
        font-size: 14px;
        background-color: #290b79A0;
        border-radius: 0 6px 6px 0;
        margin-right: 28px;
    }
}
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-usps {
        ul {
            li {
                width: 100%;
            }
        }
    }
}

@mixin tag() {
    position: absolute;
    top: 1.5rem;
    left: 2.5rem;
    padding: 4px 6px 2px 6px;
    background-color: $color-ss-darkblue;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 3px;
}
.product-options-wrapper .fieldset div {
    .option-container {
        &.tag {
            &_recommended::after {
                @include tag();
                content: "Recommended";
            }
            &_new::after {
                @include tag();
                content: "New";
            }
            &_popular::after {
                @include tag();
                content: "Popular";
            }
            &_sale::after {
                @include tag();
                content: "Sale";
            }
            &_order_online::after {
                @include tag();
                content: "Order Online";
            }
            &_request_a_quote::after {
                @include tag();
                content: "Request a Quote";
            }
            &_economy::after {
                @include tag();
                content: "Economy";
            }
            &_premium::after {
                @include tag();
                content: "Premium";
            }
        }
    }
}