// Sign Supplier Gradients
.dark-blue_pink2 {
  @include gradient-background(45deg, $color-ss-dark-blue, $color-ss-pink2);
}
.light-blue_light-purple {
  @include gradient-background(
    45deg,
    $color-ss-light-blue,
    $color-ss-light-purple
  );
}
.dark-red_indigo {
  @include gradient-background(45deg, $color-ss-dark-red, $color-ss-indigo);
}
.blue2_-light-blue-green {
  @include gradient-background(
    45deg,
    $color-ss-blue2,
    $color-ss-light-blue-green
  );
}
.muted-red_dark-pink {
  @include gradient-background(45deg, $color-ss-muted-red, $color-ss-dark-pink);
}
.pink2_dark-blue {
  @include gradient-background(45deg, $color-ss-pink2, $color-ss-dark-blue);
}
.lime_light-blue-green2 {
  @include gradient-background(
    45deg,
    $color-ss-lime,
    $color-ss-light-blue-green2
  );
}
.orange_dark-orange {
  @include gradient-background(45deg, $color-ss-orange, $color-ss-dark-orange);
}
.light-orange_yellow-orange {
  @include gradient-background(
    45deg,
    $color-ss-light-orange,
    $color-ss-yellow-orange
  );
}
.muted-red2_dark-purple {
  @include gradient-background(
    45deg,
    $color-ss-muted-red2,
    $color-ss-dark-purple
  );
}
.purple_light-blue-green-3 {
  @include gradient-background(
    45deg,
    $color-ss-purple,
    $color-ss-light-blue-green-3
  );
}
.pink3_salmon {
  @include gradient-background(90deg, $color-ss-pink3, $color-ss-salmon);
}
.dark-blue_blue3 {
  @include gradient-background(90deg, $color-ss-dark-blue, $color-ss-blue3);
}
.dark-orange2_light-orange2 {
  @include gradient-background(
    90deg,
    $color-ss-dark-orange2,
    $color-ss-light-orange2
  );
}
