//
//  Colors
//  ---------------------------------------------

$color-ss-blue: #29c4cf;
$color-ss-pink: #e62bef;
$color-ss-darkpink: #c500cf;
$color-ss-darkblue: #290b79;

$color-ss-link: #087d9b;

// $color-ss-blue: #1bbbd9;
// $color-ss-pink: #ed3c7e;

$color-ss-light-blue: #59d0db;
$color-ss-light-blue2: #f5fbfe;
$color-ss-light-purple: #a13bd5;
$color-ss-dark-red: #d93258;
$color-ss-indigo: #5501e5;
$color-ss-blue2: #1b96f1;
$color-ss-blue3: #13a8e4;
$color-ss-dark-blue: #1752a1;
$color-ss-light-blue-green: #39debd;
$color-ss-muted-red: #e54b50;
$color-ss-dark-pink: #ef32fa;
$color-ss-pink2: #e833f1;
$color-ss-pink3: #e32276;
$color-ss-dark-blue: #3543dc;
$color-ss-lime: #83da38;
$color-ss-light-blue-green2: #25c7a1;
$color-ss-salmon: #f26e4d;
$color-ss-orange: #fb8332;
$color-ss-dark-orange: #e54e4b;
$color-ss-dark-orange2: #f15e12;
$color-ss-light-orange: #fa8532;
$color-ss-light-orange2: #fdb013;
$color-ss-yellow-orange: #f8c231;
$color-ss-muted-red2: #ee4c5f;
$color-ss-dark-purple: #9f036c;
$color-ss-purple: #8c35f5;
$color-ss-light-blue-green-3: #61dec7;

//
//  Color nesting

$primary__color: $color-ss-blue !default;
$primary__color__dark: darken($primary__color, 35%) !default; // #000
$primary__color__darker: darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter: lighten($primary__color, 29%) !default; // #7d7d7d
$primary__color__light: lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color: #e8e8e8 !default;
$secondary__color__light: lighten($secondary__color, 5%) !default;

$primary__color__text: #393b3b !default;
$primary__color__text__darker: #222 !default;
$primary__color__text__lighter: #666 !default;

$page__background-color: #fff !default;
$panel__background-color: darken($page__background-color, 6%) !default;
$panel__background-color__dark: #333 !default;
$panel__divider-color: #e0e3ea;
$panel__border-color: none;

$active__color: #ff5501 !default;
$error__color: #e02b27 !default;

$disabled__color: #aaa;

//
//  Colors for contrast calc in buttons (bootstrap)

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $primary__color__text__darker !default;
$yiq-text-light: #fff !default;

//
//  Header
//  ---------------------------------------------

$header__background-color: $color-ss-darkblue !default;
$header-icons-color: #fff !default;
$header-icons-color-hover: $color-ss-blue !default;

//
//  Footer
//  ---------------------------------------------

$footer__background-color: $color-ss-darkblue !default;
