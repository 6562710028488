// Originally from Bootstrap 4.3.1

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
    $background,
    $border,
    $color: color-yiq($background),
    $hover-color: color-yiq($hover-background),
    $hover-background: lighten($background, 9%),
    $hover-border: lighten($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);
    text-align: center;
    transition: $button-transition;
    outline: none;

    @include hover {
        color: $hover-color;
        @include gradient-bg($hover-background);
        border-color: $hover-border;
        text-decoration: none;
        box-shadow: 0 1px 8px 0 $border;
        transform: translateY(-1px);
    }

    &:focus,
    &.focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $btn-box-shadow,
                0
                    0
                    0
                    $btn-focus-width
                    rgba(mix(color-yiq($background), $border, 15%), 0.5);
        } @else {
            box-shadow: 0
                0
                0
                $btn-focus-width
                rgba(mix(color-yiq($background), $border, 15%), 0.5);
        }
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: $background;
        border-color: $border;
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
            background-image: none;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0
                        0
                        0
                        $btn-focus-width
                        rgba(mix(color-yiq($background), $border, 15%), 0.5);
            } @else {
                box-shadow: 0
                    0
                    0
                    $btn-focus-width
                    rgba(mix(color-yiq($background), $border, 15%), 0.5);
            }
        }
    }
}

@mixin button-ghost-variant(
    $color,
    $color-outline: $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    background-color: transparent;
    color: $color;
    border-width: 1px;
    border-style: solid;
    border-color: $color-outline;
    text-align: center;
    transition: $button-transition;

    @include hover {
        //color: lighten($color, 8%);
        color: $color;
        background-color: inherit;
        border-color: lighten($color, 8%);
        text-decoration: none;
        box-shadow: 0 1px 8px 0 lighten($color, 8%);
        transform: translateY(-1px);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

@mixin button-outline-variant(
    $color,
    $border-color: $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    background-color: transparent;
    color: $color;
    border-width: 1px;
    border-style: solid;
    border-color: $border-color;
    text-align: center;
    transition: $button-transition;
    outline: none;

    @include hover {
        color: lighten($color, 8%);
        background-color: inherit;
        border-color: lighten($border-color, 8%);
        text-decoration: none;
        box-shadow: 0 1px 12px 0 lighten($border-color, 8%);
        transform: translateY(-1px);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($border-color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

@mixin button-link-variant(
    $color,
    $border-color: $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    background-color: transparent;
    color: $color;
    border-width: 1px;
    border-style: solid;
    border-color: $border-color;
    text-align: center;
    transition: $button-transition;
    outline: none;

    @include hover {
        color: lighten($color, 8%);
        background-color: inherit;
        border-color: lighten($color, 8%);
        text-decoration: none;
        box-shadow: 0 1px 12px 0 lighten($color, 8%);
        transform: translateY(-1px);
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

@mixin button-underline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    background-color: transparent;
    color: $color;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    text-align: center;
    transition: $button-transition;
    outline: none;
    //

    @include hover {
        color: lighten($color, 8%);
        background-color: inherit;
        text-decoration: underline;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow,
                    0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}

// Button sizes
@mixin button-size(
    $padding-y,
    $padding-x,
    $font-size,
    $font-weight,
    $line-height,
    $border-radius
) {
    padding: $padding-y $padding-x;
    //@include@include font-size($font-size);
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    border-radius: $border-radius;
}
