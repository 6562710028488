//
//  Link
//  ---------------------------------------------

$link-transition: all 0.2s ease;
$button-transition: $link-transition;

//
//  Button
//  ---------------------------------------------

$btn-box-shadow: none !default;
$btn-focus-width: 0.2rem !default;
$btn-focus-box-shadow: none !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: none !default;
$btn-min-width: 16rem !default;
$btn-border-radius: 10px !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
