$panel-border-radius: 10px;
$panel-border-radius-small: 4px;
$panel-shadow: 0px 2px 10px #290b791f;
$panel-shadow-hover: 0 3px 15px 0 #280b792c;
$panel-shadow-hover-transform: translateY(-3px);
$panel-content-padding: 3rem;
$panel-content-grid-gap: $content-grid-gap;

$panel-background-color: #fff;
$panel-background-color-dark: #333;
