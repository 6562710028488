//
//  Sections variables
//  _____________________________________________

//
//  Tabs
//  ---------------------------------------------

//  Tabs controls
$tab-control__font-size: $font-size__base !default;
$tab-control__font-family: false !default;
$tab-control__font-weight: $font-weight__semibold !default;
$tab-control__font-style: false !default;
$tab-control__height__base: $indent__base + $indent__xs * 2 !default;
$tab-control__height: unset !default;
$tab-control__line-height: 1.25 !default;
$tab-control__margin-right: 0 !default;
$tab-control__padding-top: $indent__xs !default;
$tab-control__padding-right: $indent__base !default;
$tab-control__padding-bottom: $tab-control__padding-top !default;
$tab-control__padding-left: $tab-control__padding-right !default;
$tab-control__border-color: $border-color__base !default;
$tab-control__border-width: 0 !default;
$tab-control__highlight-border-width: 1px !default;

//  Current tab
$tab-control__background-color: #fff !default;
$tab-control__color: $color-ss-link !default;
$tab-control__text-decoration: none !default;

$tab-control__hover__background-color: #fff !default;
$tab-control__hover__color: $color-ss-blue !default;
$tab-control__hover__text-decoration: $tab-control__text-decoration !default;

$tab-control__active__background-color: lighten(
    $tab-control__background-color,
    15%
) !default;
$tab-control__active__color: $text__color !default;
$tab-control__active__text-decoration: $tab-control__text-decoration !default;
$tab-control__active__border-color: $color-ss-blue;

$tab-content__background-color: $tab-control__active__background-color !default;
$tab-content__border-top-color: $panel__divider-color;
$tab-content__border-top-status: false !default;
$tab-content__border: none !default;
$tab-content__margin-top: $tab-control__height + $tab-control__border-width +
    $tab-control__padding-top + $tab-control__padding-bottom !default;
$tab-content__padding-top: $indent__base !default;
$tab-content__padding-right: 0 !default;
$tab-content__padding-bottom: 0 !default;
$tab-content__padding-left: 0 !default;

//
//  Accordions
//  ---------------------------------------------

$accordion-control__font-family: $tab-control__font-family !default;
$accordion-control__font-size: $font-size__l !default;
$accordion-control__font-style: $tab-control__font-style !default;
$accordion-control__font-weight: $tab-control__font-weight !default;
$accordion-control__height: unset !default;
$accordion-control__line-height: normal !default;
$accordion-control__border-top: none !default;
$accordion-control__border-right: none !default;
$accordion-control__border-bottom: none !default;
$accordion-control__border-left: none !default;
$accordion-control__background-color: $tab-control__background-color !default;
$accordion-control__color: $tab-control__color !default;
$accordion-control__text-decoration: $tab-control__text-decoration !default;
$accordion-control__margin-bottom: $indent__xs !default;
$accordion-control__padding-top: $tab-control__padding-top !default;
$accordion-control__padding-right: $indent__base !default;
$accordion-control__padding-bottom: $tab-control__padding-bottom !default;
$accordion-control__padding-left: 0 !default;

$accordion-control__visited__color: $accordion-control__color !default;
$accordion-control__visited__text-decoration: $accordion-control__text-decoration !default;

$accordion-control__hover__background-color: $tab-control__hover__background-color !default;
$accordion-control__hover__color: $tab-control__hover__color !default;
$accordion-control__hover__text-decoration: $tab-control__hover__text-decoration !default;

$accordion-control__active__background-color: $tab-control__active__background-color !default;
$accordion-control__active__color: $tab-control__active__color !default;
$accordion-control__active__text-decoration: $tab-control__active__text-decoration !default;

$accordion-content__background-color: $tab-control__active__background-color !default;
$accordion-content__border: none !default;
$accordion-content__margin: 0 0 $indent__xs !default;
$accordion-content__padding: $tab-content__padding-top 0
    $tab-content__padding-top 0 !default;
