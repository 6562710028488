.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

.page-header,
.page-footer {
    .switcher {
        //margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Assorted
//  ---------------------------------------------

// Post content-header content
.post-content-header-wrapper {
    margin-top: -2rem;
    margin-bottom: 0;
    position: relative;
    z-index: 5;
    @media (max-width: 1024px) {
        margin-top: 0;
    }
}

// Get started
.home-get-started {
    position: relative;
    display: flex;
    //grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: $grid-list-gap;
    // .n2-panel {
    //     background: linear-gradient(160deg, #6141b5 0%, #4b328c 28%, #290b79 100%, #17034c 100%);
    //     color: #fff;
    //     &:hover {
    //         color: #fff;
    //     }
    //     .icon-content-stack {
    //         color: #fff;
    //     }
    //     .panel-link-text {
    //         color: #fff;
    //     }
    // }
    @media (max-width: $screen__l) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: $grid-list-gap / 2;
    }
}

.our-customers {
    display: grid;
    grid-template-rows: auto;
    .title {
        margin-top: 0;
        @media (max-width: $screen__s) {
            text-align: center;
        }
    }
    .customer-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        justify-content: center;
        align-items: center;
        gap: 5rem;
        padding: 2rem 0;
        .icon {
            width: 100%;
        }
        @include max-screen($screen__xl) {
            .icon:nth-of-type(1n + 7) {
                display: none;
            }
        }
    }
}

// #popular-products {
//     .product-list {
//         // display: grid;
//         // grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
//         // gap: 1rem;
//     }
// }

// Text expander
.expand-text,
.glide-expand-text {
    position: relative;
    overflow: hidden;
    height: 4.2em;
    transition: height 0.3s ease-out;
    &::after {
        content: " ";
        @include position-absolute-fill();
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 30%,
            white 100%
        );
        transition: opacity 0.2s ease;
        pointer-events: none;
    }
    p:last-child {
        margin-bottom: 0;
    }
    &.lines-3 {
        height: 4.2em;
    }
    &.lines-6 {
        height: 8em;
    }
    &.lines-9 {
        height: 12em;
    }
    &.lines-12 {
        height: 16em;
    }
    &[aria-expanded="true"] {
        // overflow: visible;
        // height: auto;
        &::after {
            opacity: 0;
        }
    }
}
.expander,
.glide-expander,
.expand-btn {
    background: transparent;
    color: $color-ss-blue;
    display: inline-block;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    padding-left: 0;
    padding-right: 0;
    &::after {
        content: "▼";
        display: inline-block;
        margin-left: 0.5rem;
        transition: all 0.2s ease;
        font-size: 0.8em;
    }
    &:hover {
        color: $color-ss-blue;
        border: none;
        &::after {
            transform: translateY(3px);
        }
    }
    &:focus,
    &:active {
        color: $color-ss-blue;
        border: none;
    }
    &.open {
        &::after {
            transform: rotate(180deg) translateY(2px);
        }
        &:hover {
            &::after {
                transform: rotate(180deg) translateY(5px);
            }
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

.required-captcha.checkbox {
    display: none;
}

.field-recaptcha {
    .g-recaptcha {
        > div {
            margin: 0 auto;
        }
    }
}

#cookie-status {
    display: none;
}