#intercom-facade {
    position: fixed;
    z-index: 2147483003;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-ss-blue;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06),
        0 2px 32px 0 rgba(0, 0, 0, 0.16);
    > svg {
        width: 30px;
        height: 30px;
    }
    &:hover,
    &:active,
    &:focus {
        background-color: $color-ss-blue;
    }
    &.is-loading {
        pointer-events: none;
        animation: pulse 1.2s ease-in-out infinite alternate;
    }
}

@keyframes pulse {
    0% {
        background: $color-ss-blue;
        border-color: $color-ss-blue;
    }
    50% {
        background: lighten($color-ss-blue, 9%);
        border-color: lighten($color-ss-blue, 9%);
    }
    100% {
        background: $color-ss-blue;
        border-color: $color-ss-blue;
    }
}
