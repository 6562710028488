section {
    margin-top: 8rem;
    margin-bottom: 8rem;
    &.inset {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.grid-item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: $grid-list-gap;
    padding: 0;
    list-style: none;
    @include min-screen($screen__xl) {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    @include max-screen($screen__m) {
        gap: $grid-list-gap / 2;
    }
    &.gap-x2 {
        gap: $grid-list-gap * 2;
    }
    &.item-size {
        &__sm {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        }
        &__sm-fixed {
            grid-template-columns: repeat(auto-fill, minmax(120px, 180px));
            justify-content: center;
        }
        &__smmd {
            grid-template-columns: repeat(auto-fill, minmax(240, 1fr));
            @include min-screen($screen__xl) {
                grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            }
        }
        &__md {
            grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
        }
        &__md-fixed {
            grid-template-columns: repeat(auto-fill, minmax(240px, 300px));
            justify-content: center;
        }
        &__lg {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
        &__lg-fixed {
            grid-template-columns: repeat(auto-fill, minmax(320px, 400px));
            justify-content: center;
        }
        &__xl {
            grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));

            @include max-screen($screen__s) {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            }
        }

        &__full {
            grid-template-columns: 1fr;
        }
    }
}

.content-stack-edges {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1rem;
    text-align: right;
}

.icon-content-stack {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 2rem;
    color: $color-ss-darkblue;
    .title {
        display: block;
        color: inherit;
        font-size: 1.8rem;
        font-weight: 400;
        padding-right: 3rem;
    }
    .subtitle {
        color: inherit;
        font-size: 1.6rem;
        font-weight: 300;
    }
    &.white {
        color: #fff;
    }
}

.content-image-stack {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    height: 100%;
    gap: 2rem;
    @include max-screen($screen__l) {
        grid-template-columns: 1fr 1fr;
    }
    @include max-screen($screen__m) {
        grid-template-columns: auto;
        gap: 0;
    }
    &.flip {
        grid-template-columns: auto 1fr;
        @include max-screen($screen__m) {
            grid-template-columns: auto;
            gap: 0;
        }
    }
    .content {
        padding: 6rem 3rem;
        &.grid {
            display: grid;
            grid-template-rows: auto;
            gap: $content-grid-gap;
            .button {
                justify-self: start;
            }
        }
        @include max-screen($screen__m) {
            order: 2;
            padding: 3rem;
        }
        .title {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .image {
        font-size: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include max-screen($screen__m) {
            order: 1;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.grid-check-list {
    display: grid;
    grid-template-rows: auto;
    gap: 0.6rem;
    p, span, strong, a {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        &::before {
            content: "";
            width: 1em;
            height: 0.7em;
            padding-right: 2.4rem;
            color: $color-ss-blue;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.828' height='8.414' viewBox='0 0 12.828 8.414'%3E%3Cpath d='M7090 6532.412l3.747 3.6 6.253-6' transform='translate(-7088.586 -6528.593)' fill='none' stroke='%2329c4cf' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            transform: scale(1.2);
        }
    }
}