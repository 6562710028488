.nav-wrapper {
    background-color: $navigation__background;
    z-index: 5;
}

.net22-nav {
    a {
        color: $color-ss-darkblue;
        text-decoration: none;
        padding: 1.2rem 2rem;
        display: block;
        font-weight: 500;
        &:hover {
            color: $color-ss-blue;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 6;
    }

    li {
        display: block;
        position: relative;
        text-decoration: none;
        transition-duration: 0.5s;
        margin-bottom: 0;
        > a {
            color: $color-ss-darkblue;
        }
    }

    #menu-button {
        display: none;
    }
    .menu-line {
        display: none;
    }

    @include min-screen($screen__m) {
        ul {
            justify-content: space-between;
        }

        ul li ul {
            background: #fff;
            visibility: hidden;
            opacity: 0;
            min-width: 260px;
            position: absolute;
            transition: opacity 0.5s ease;
            display: none;
            padding: 1rem 0;
            box-shadow: $panel-shadow-hover;
            &.active {
                transition-delay: 1s;
            }
        }

        li li:hover,
        li:focus-within {
            color: $color-ss-blue;
            cursor: pointer;
        }

        li:focus-within a {
            outline: none;
        }

        ul li {
            &:first-child {
                ul {
                    left: 0;
                }
            }
            &:last-child {
                ul {
                    left: unset;
                    right: 0;
                }
            }
        }

        ul li:hover > ul,
        ul li:focus-within > ul,
        ul li ul:hover,
        ul li ul:focus {
            visibility: visible;
            opacity: 1;
            display: block;
        }

        ul li ul li {
            color: $color-ss-darkblue;
            clear: both;
            width: 100%;
            a {
                color: $color-ss-darkblue;
                &:hover {
                    color: $color-ss-blue;
                }
            }
        }
    }
    // mobile menu
    @include max-screen($screen__m) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 17;
        -webkit-user-select: none;
        user-select: none;
        #menu-button {
            display: block;
            width: 7rem;
            height: 6rem;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 19;
        }
        .menu-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 7rem;
            height: 6rem; // Nice 
            gap: 5px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 18;
        }
        .menu-line {
            display: flex;
            width: 29px;
            height: 2px;
            position: relative;
            background: $color-ss-pink;
            border-radius: 3px;
            transform-origin: 5px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
                opacity 0.55s ease;
        }
        .menu-line:first-child {
            transform-origin: 0% 0%;
        }

        .menu-line:last-child {
            transform-origin: 0% 100%;
        }

        input:checked ~ .menu-icon > .menu-line {
            opacity: 1;
            transform: rotate(45deg) translate(-3px, -3px);
        }
        input:checked ~ .menu-icon > .menu-line:nth-child(2) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        input:checked ~ .menu-icon > .menu-line:last-child {
            transform: rotate(-45deg) translate(-1px, 1px);
        }

        input:checked ~ ul {
            transform: none;
        }

        > ul {
            position: absolute;
            width: 90vw;
            height: 100vh;
            box-shadow: $panel-shadow-hover;
            margin: 0 0 0 -20px;
            padding: 40px 20px 40px 40px;
            background-color: $navigation__background;
            overflow-y: scroll;
            overflow-x: clip;
            -webkit-font-smoothing: antialiased;
            transform-origin: 0% 0%;
            transform: translate(-120%, 0);
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            z-index: 17;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            > li > a {
                color: $color-ss-darkblue;
            }
        }

        ul li ul {
            padding: 1rem 0 2rem 2rem;
        }

        ul li ul li {
            color: #fff;
            clear: both;
            width: 100%;
            a {
                color: $text__color;
                &:hover {
                    color: $color-ss-blue;
                }
            }
        }
    }
}

.nav-button-highlight {
    background-color: $color-ss-blue;
    color: $color-ss-darkblue;
    padding: 1.2rem 2rem;
    line-height: 1.4;
    border: none;
    border-radius: 0;
    outline: none;

    &:hover {
        background-color: $color-ss-blue;
        color: #fff;
        border: none;
        border-radius: 0;
        outline: none;
    }

    &:active,
    &:focus {
        background-color: $color-ss-blue;
        color: #fff;
        padding: 1.2rem 2rem;
        border: none;
        border-radius: 0;
        outline: none;
    }
}